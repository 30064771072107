import React from 'react';
import {Route, Switch} from "react-router";
import {Redirect} from "react-router-dom";
import {MainLayout} from "./components/Layouts/MainLayout/MainLayout";
import {Home} from "./pages/home/Home";
import {Dex} from "./pages/dex/Dex";
import {GoldHistory} from "./pages/history/GoldHistory";
import {About} from "./pages/about/About";


export const useRoutes = (isAuth) => {
  return (
    <MainLayout>
      <Switch>
        <Route path="/" exact={true}>
          <Home/>
        </Route>

        <Route path="/about" exact={true}>
          <About/>
        </Route>

        <Route path="/history-gold" exact={true}>
          <GoldHistory/>
        </Route>

        <Route path="/">
          <Redirect to={'/'}/>
        </Route>
      </Switch>
    </MainLayout>
  )
}