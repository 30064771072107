import React from "react";
import "./HomeBlock5.css";

export const HomeBlock5 = () => {
  return (
    <div className="home-block-5">
      <div className="content">
        <div className="home-block-5-center">
          <div>
            <h3>Media</h3>
            <div className="media-container">

              <div className="media-item" onClick={() => {
                window.open("https://www.einnews.com/pr_news/584620263/lapland-minerals-inc-announces-the-first-audit-report-of-assets-backing-up-their-token", "_blank")
              }}>
                <div className="logo-container">
                  <div className="presswire-logo"/>
                  <div className="hr"/>
                </div>
                <div className="description">
                  Lapland Minerals Inc. announces the first audit report of assets backing up their token.
                </div>
                <div className="date">
                  NewsWires, 5 August 2022
                </div>
              </div>

              <div className="media-item" onClick={() => {
                window.open("https://www.einnews.com/pr_news/579746204/lapland-minerals-inc-announces-a-second-collateral-agreement-to-secure-the-token-value", "_blank")
              }}>
                <div className="logo-container">
                  <div className="presswire-logo"/>
                  <div className="hr"/>
                </div>
                <div className="description">
                  Lapland Minerals Inc. announces a second collateral agreement to secure the token value.
                </div>
                <div className="date">
                  NewsWires, 4 July 2022
                </div>
              </div>

              <div className="media-item" onClick={() => {
                window.open("https://www.einnews.com/pr_news/570589343/lapland-minerals-inc-announces-the-first-public-survey-reports-to-contain-immense-verified-gold-deposits", "_blank")
              }}>
                <div className="logo-container">
                  <div className="presswire-logo"/>
                  <div className="hr"/>
                </div>
                <div className="description">
                  Lapland Minerals Inc announces the first public survey reports to contain immense verified gold deposits
                </div>
                <div className="date">
                  NewsWires, 2 May 2022
                </div>
              </div>

              <div className="media-item" onClick={() => {
                window.open("https://www.issuewire.com/lapland-minerals-inc-launches-its-first-mineral-backed-token-on-binance-smart-chain-1728924644920228", "_blank")
              }}>
                <div className="logo-container">
                  <div className="issuewire-logo"/>
                  <div className="hr"/>
                </div>
                <div className="description">
                  Lapland Minerals Inc. launches its first mineral-backed token on Binance Smart Chain.
                </div>
                <div className="date">
                  IssueWire, 2 April 2022
                </div>
              </div>

              <div className="media-item" onClick={() => {
                window.open("https://www.coindesk.com/business/2022/02/03/bank-of-russia-approves-atomyze-as-first-digital-asset-issuer/", "_blank")
              }}>
                <div className="logo-container">
                  <div className="coindesk-logo"/>
                  <div className="hr"/>
                </div>
                <div className="description">
                  Bank of Russia Approves the First Digital Asset Issuer backed by Minerals.
                </div>
                <div className="date">
                  Coindesk, 3 Februari 2022
                </div>
              </div>

              <div className="media-item" onClick={() => {
                window.open("https://www.bloomberg.com/news/videos/2021-09-22/tremendous-demand-for-digital-metal-investing-atomyze-ceo-video", "_blank")
              }}>
                <div className="logo-container">
                  <div className="bloomberg-logo"/>
                  <div className="hr"/>
                </div>
                <div className="description">
                  Tremendous Demand for Digital Metal Investing. Video intervju with CEO of Atomyze.
                </div>
                <div className="date">
                  Bloomberg, 22 September 2021
                </div>
              </div>

              <div className="media-item" onClick={() => {
                window.open("https://www.spglobal.com/marketintelligence/en/news-insights/latest-news-headlines/tokenizing-mining-metals-assets-may-open-sector-to-fresh-investor-base-54079355", "_blank")
              }}>
                <div className="logo-container">
                  <div className="sp-logo"/>
                  <div className="hr"/>
                </div>
                <div className="description">
                  Tokenizing mining, metals assets may open sector to fresh investor base.
                </div>
                <div className="date">
                  S&P Global Marketing Intelligence, 10 September 2019
                </div>
              </div>

            </div>



            <h3>Technology</h3>
            <div className="tech-container">
              <div className="tech-item">
                <div className="tech-coinbase-logo"/>
                <div className="name">Coinbase</div>
                <div className="country">Ireland</div>
              </div>
              <div className="tech-item">
                <div className="tech-trust-wallet-logo"/>
                <div className="name">Trust Wallet</div>
                <div className="country">U.S.A.</div>
              </div>
              <div className="tech-item">
                <div className="tech-metamask-logo"/>
                <div className="name">MetaMask Wallet</div>
                <div className="country">U.S.A.</div>
              </div>
              <div className="tech-item">
                <div className="tech-binance-logo"/>
                <div className="name">Binance</div>
                <div className="country">Cayman Islands</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}