import React from "react";
import "./Header.css";
import {MainMenu} from "../MainMenu/MainMenu";
import {useHistory} from "react-router-dom";

export const Header = () => {
  const history = useHistory();

  return (
    <div className="header">
      <div className="logo" onClick={() => history.push("/")}/>
      <MainMenu/>
    </div>
  )
}