import React from "react";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import "./FAQ.css";
import {isMobile} from "../../../utils/checkDevice";

export const FAQ = () => {

  const data = [
    {
      title: "What is the Lapland Mineral Gold Token (LMGT)?",
      text: "Cryptographic token created on Binance Smart Chain; the value of LMGT is always equal to the market price of 0,1 grams of Gold."
    },
    {
      title: "What is creating the value of LMGT?",
      text: "Lapland Minerals Inc. has a collateral agreement backing up each batch of tokens it has created. In the case of LMGT, the collateral is 120 tons of Gold in the ground, divided by 1,2 billion tokens, making the portion for each token 0,1 gram of Gold."
    },
    {
      title: "How is the market price going to be in the future?",
      text: "The value of the token will be the same 0,1 grams of Gold; whatever that is in Eur or USD, we do not know since the currency rates are in constant change. The nature of public markets allows the buyer and the seller to perform the trade at any price they agree, so we only know that the value of each LMGT is 0,1 grams of Gold."
    },
    {
      title: "For what purpose is LMGT created?",
      text: "LMGT's primary purpose is to store the value the owner has created."
    },
    {
      title: "Can LMGT be used as means of exchange, like other currencies?",
      text: "Yes, when LMGT has been listed for public trading, it can be used as \"currency.\""
    },
    {
      title: "Tokenomics for LMGT?",
      text: "Lapland Minerals Inc. creates the tokens for free markets without any limitations on the usage of the tokens."
    },
    {
      title: "Is LMGT a stablecoin?",
      text: "LMGT is not stablecoin since it is not pegged against any fiat currency, other cryptocurrencies, or algorithmic program. Instead, we call LMGT \"Mineral coin\" since the collateral backing the value is based on un-mined minerals remaining in the ground."
    },
    {
      title: "How does it differ from Gold pegged tokens?",
      text: "The gold backing the LMGT is still in the ground, not mined, melted, or stored in any vault; in both cases, a certain amount of Gold is verified and reserved for backing the token value."
    },
    {
      title: "Do I need to register the ownership of LMGT the same way as I do with physical Gold?",
      text: "No, you are not; it is treated as any other crypto token available on the market today, and therefore you need to include it in your tax reports."
    },
    {
      title: "How does Lapland Minerals Inc. operate?",
      text: "The process is as follows: The collateral agreement is made with the collateral provider, including a third-party report of the minerals in question. A third-party audit report is acquired regarding the validity of the contract. A third-party operator performs initial \"AirDrop\" or similar pre-marketing, and a presale is initiated directly from the company site. Finally, a public listing is completed making the token available for retail users worldwide. "
    },
    {
      title: "How do Lapland Minerals Inc. remunerate the collateral providers?",
      text: "Providers are remunerated during the sales process based on the price agreed upon while signing the agreement. Money flow is the buyer pays to Lapland Minerals Inc, and Lapland Minerals Inc. then settles with the collateral provider."
    },
    {
      title: "Where is the money spent on marketing, and where are the discounts coming from?",
      text: "It is the money not devoured on processes from mining to storing the gold bars in the vault."
    }
  ];


  return (
    <div className="faq">
      <div className="content">
        <div className="faq-center">
          <div className="faq-title1">FAQ</div>
          <div className="faq-title2">Here you get answers of the most asked questions.</div>
          <div className="faq-container">
            {data.map((e, i) => {
              return <Accordion key={i} className="faq-item">
                <AccordionSummary aria-controls="panel1d-content">
                  <div className="faq-caption">
                    <div>{e.title}</div>
                    {!isMobile() && <div className="faq-icon"/>}
                  </div>

                </AccordionSummary>
                <AccordionDetails>
                  <div className="faq-details">
                    {e.text}
                  </div>
                </AccordionDetails>
              </Accordion>
            })}
          </div>
        </div>
      </div>
    </div>
  )
}