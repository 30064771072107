import React from "react";
import {HomeBlock1} from "./HomeBlock1/HomeBlock1";
import {HomeBlock2} from "./HomeBlock2/HomeBlock2";
import {HomeBlock3} from "./HomeBlock3/HomeBlock3";
import {HomeBlock4} from "./HomeBlock4/HomeBlock4";
import {HomeBlock5} from "./HomeBlock5/HomeBlock5";
import {OurHistory} from "./OurHistory/OurHistory";
import {FAQ} from "./FAQ/FAQ";

export const Home = () => {
  return(
    <div>
      <HomeBlock1/>
      <HomeBlock2/>
      <OurHistory/>
      <FAQ/>
      <HomeBlock3/>
      <HomeBlock4/>
      <HomeBlock5/>
    </div>
  )
}