import React, {useEffect, useState} from "react";
import "./HomeBlock3.css";
import {Line} from "react-chartjs-2";
import {defaults} from 'react-chartjs-2';
import {isMobile} from "../../../utils/checkDevice";
import axios from "axios";
import {toEur} from "../../../utils/tools";

defaults.color = '#fff';

export const HomeBlock3 = () => {
  const [rate, setRate] = useState();

  const [rateHistory, setRateHistory] = useState();
  const [rates, setRates] = useState();
  const [dates, setDates] = useState();

  useEffect(() => {
    axios.get("https://payment.onetalk.eu/api/v1/currencies/rates/history/XAU/last")
      .then(response => setRate(response.data));
  }, []);

  const dataSet = {
    labels: dates && dates,
    datasets: [
      {
        label: 'EUR',
        fill: "start",
        lineTension: 0,
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 280);
          gradient.addColorStop(0, "rgba(0, 122, 255, 0.33)");
          gradient.addColorStop(1, "rgba(255, 255, 255, 0.7)");
          return gradient;
        },
        borderColor: '#FFF',
        borderWidth: 1,
        data: rates && rates
      }
    ]
  }

  useEffect(async () => {
    const _dates = [];
    const _rates = [];
    await axios.get("https://payment.onetalk.eu/api/v1/currencies/rates/history/XAU/last/10")
      .then(response => {
        response.data.forEach(e => {
          const d = new Date(Date.parse(e.date)).toLocaleString();
          _dates.push(d.substr(0, d.indexOf("/2022")) + " " + d.substr(d.indexOf(",") + 2, d.length - 15))
        });
        response.data.forEach(e => _rates.push(e.eurRate));
      });
    setDates(_dates.reverse());
    setRates(_rates.reverse());
  }, []);


  return (
    <div className="home-block-3">
      <div className="content">
        <div className="home-block-3-center">
          {!isMobile() &&
          <div>
            <div className="home-block-3-caption">Gold chart</div>
            <div className="home-block-3-description">Gold price per gram</div>
            <div>
              {dataSet && <Line
                data={dataSet}
                options={{
                  title: {
                    display: true,
                    text: 'Gold',
                    fontSize: 20
                  },
                  plugins: {
                    legend: {
                      display: false
                    }
                  }
                }}
              />}
            </div>

            {rate &&
            <div className="gold-price-form">
              <div>
                <div>Gold per gram</div>
                <div>{toEur(rate.eurRate)}</div>
                <div>{rate.date.replace("T", " ")} UTC</div>
              </div>
            </div>
            }

          </div>
          }

          <div>
            <h3>Pay with Gold</h3>
            <div className="pay-with-gold-text">
              Lapland Minerals Inc created LMGT to offer astute investors a way to gain access to Gold claim assets
              as an investment without the negative environmental effect of mining and the burden of physically storing
              and securing the Gold itself.
              <br/>
              <br/>
              Lapland Minerals Inc will allow owners of LMGTs to begin to use Gold as a currency again, allowing the
              digital realm to offer utility in the form of frictionless and immutable payments to and from anywhere in
              the World.
            </div>
            {isMobile() &&
            <div>
              <h3>Gold chart</h3>
              <div>
                <Line
                  data={dataSet}
                  options={{
                    title: {
                      display: true,
                      text: 'GOLD',
                      fontSize: 20
                    },
                    plugins: {
                      legend: {
                        display: false
                      }
                    }
                  }}
                />
              </div>
            </div>}
          </div>
        </div>
      </div>
    </div>
  )
}