import React, {useEffect, useState} from "react";
import "./MainMenu.css"
import {useHistory} from "react-router-dom";
import {Modal} from "@mui/material";
import {Close} from "@mui/icons-material";
import {isMobile} from "../../utils/checkDevice";

export const MainMenu = () => {
  const history = useHistory();
  const [active, setActive] = useState(0);
  const [openModalWallet, setOpenModalWallet] = useState(false);

  const handleOpenModalWallet = () => {
    setOpenModalWallet(true);
  };
  const handleCloseModalWallet = () => {
    setOpenModalWallet(false);
  };

  const modalBodyWallet = (
    <div className="modal">
      <div className="modal-close-button"><Close onClick={handleCloseModalWallet}
                                                 fontSize={isMobile() ? "small" : "medium"}/></div>
      <div className="modal-content">
        <div>
          During pre-sales the LMGT token wallet can be created using suggested wallets below. The token will not
          transfer onto your wallets until pre-sales has ended.
          <br/>
          <br/>
          Choose what wallet you want to set-up if you don’t already have one.
        </div>
        <div className="modal-wallets-logo">
          <div className="metamask-logo" onClick={() => {
            handleCloseModalWallet();
            window.open("https://metamask.io/", "_blank");
          }}/>
          <div className="trust-wallet-logo" onClick={() => {
            handleCloseModalWallet();
            window.open("https://trustwallet.com/", "_blank")
          }}/>
        </div>
      </div>
    </div>
  );


  useEffect(() => {
    history.listen(
      () => {
        let path = window.location.pathname;
        if (path === "/") {
          setActive(0);
        }
        if (path === "/about") {
          setActive(1);
        }
        if (path === "/history-gold") {
          setActive(3);
        }
      }
    );
  }, []);

  return (
    <div className="main-menu">
      <div className={active === 0 ? "active" : ""} onClick={() => history.push("/")}>Home</div>
      <div className={active === 1 ? "active" : ""} onClick={() => history.push("/about")}>About us</div>
      <div className={active === 2 ? "active" : ""}
           onClick={() => window.open("https://laplandminerals.com/whitepaper.pdf")}>Whitepaper
      </div>
      <div className={active === 3 ? "active" : ""} onClick={() => history.push("/history-gold")}>History Gold</div>
      <div className="create-wallet-button" onClick={handleOpenModalWallet}>Create wallet</div>

      <Modal
        open={openModalWallet}
        onClose={handleCloseModalWallet}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodyWallet}
      </Modal>
    </div>
  )
}