import React from "react";
import "./AboutBlock1.css";
import "../About.css";
import {isMobile} from "../../../utils/checkDevice";

export const AboutBlock1 = () => {
  return (
    <div className="about-block-1">
      <div className="content">
        <div className="about-block-1-center">
          <div className="about-caption">Todays cryptoworld</div>
          <div className="about-description">
            <div>Imagine a crypto token that can store value over time and is backed up by something precious in the
              physical world. Imagine that this token also can be used as a medium of exchange. Exchange and value are
              precisely what the founders of Lapland Mineral Gold Token imagined.
            </div>
            <div>At Lapland Minerals, we have created a new kind of crypto token that will store value considerably
              better than any existing token on the market today. The Lapland Mineral Gold Token is pegged to actual
              physical gold in an environmentally friendly way. Traditionally one would have to start a colossal
              mining operation to dig the gold out of the ground in a polluting and rather disastrous fashion to
              nature. Then the gold should have been transported and stored in a secure vault somewhere in the world.
              After completing this process, one could use gold as collateral for loans or issue digital tokens. But
              Lapland Minerals do it differently.
            </div>
            <div>We secure the mining claims, verify how much gold there is in the ground, and then leave it there!
              What better vault is there than the exact spot mother nature picked when she created it? We couldn't
              find any, and we won't destroy mother nature to try either.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}