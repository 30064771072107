import React from "react";
import {Header} from "../../Header/Header";
import {Footer} from "../../Footer/Footer";
import {isMobile} from "../../../utils/checkDevice";
import {HeaderMobile} from "../../Header/HeaderMobile";
import {FooterMobile} from "../../Footer/FooterMobile";

export const MainLayout = ({children}) => {

  return (
    <>
      <div className="container">
        {isMobile()? <HeaderMobile/> : <Header/>}
        {children}
        {isMobile()? <FooterMobile/> : <Footer/>}
      </div>
    </>
  )
}