import React, {useState} from "react";
import "./HeaderMobile.css";
import {Modal, SwipeableDrawer} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {useHistory} from "react-router-dom";
import {Close} from "@mui/icons-material";
import {isMobile} from "../../utils/checkDevice";

export const HeaderMobile = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [openModalWallet, setOpenModalWallet] = useState(false);

  const handleOpenModalWallet = () => {
    setOpenModalWallet(true);
  };
  const handleCloseModalWallet = () => {
    setOpenModalWallet(false);
  };

  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const modalBodyWallet = (
    <div className="modal">
      <div className="modal-close-button"><Close onClick={handleCloseModalWallet}
                                                 fontSize={isMobile() ? "small" : "medium"}/></div>
      <div className="modal-content">
        <div>
          During pre-sales the LMGT token wallet can be created using suggested wallets below. The token will not
          transfer onto your wallets until pre-sales has ended.
          <br/>
          <br/>
          Choose what wallet you want to set-up if you don’t already have one.
        </div>
        <div className="modal-wallets-logo">
          <div className="metamask-logo" onClick={() => {
            handleCloseModalWallet();
            window.open("https://metamask.io/", "_blank");
          }}/>
          <div className="trust-wallet-logo" onClick={() => {
            handleCloseModalWallet();
            window.open("https://trustwallet.com/", "_blank")
          }}/>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="header-mobile">
        <MenuIcon className="menu-icon" fontSize="large" onClick={() => setOpen(true)}/>
        <div className="logo-mobile"/>
      </div>

      <SwipeableDrawer
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
      >
        <div className="mobile-menu">
          <div className="mobile-menu-item" onClick={() => {
            history.push("/");
            setOpen(false);
          }}>Home</div>
          <div className="mobile-menu-item" onClick={() => {
            history.push("/about");
            setOpen(false);
          }}>About Us</div>
          <div className="mobile-menu-item" onClick={() => {
            setOpen(false);
            window.open("https://laplandminerals.com/whitepaper.pdf")
          }}>Whitepaper</div>
          {/*<div className="mobile-menu-item" onClick={() => {*/}
          {/*  history.push("/audit");*/}
          {/*  setOpen(false);*/}
          {/*}}>Audit report</div>*/}
          <div className="mobile-menu-item" onClick={() => {
            history.push("/history-gold");
            setOpen(false);
          }}>History Gold</div>
          <div className="mobile-menu-item" onClick={() => {
            setOpen(false);
            handleOpenModalWallet();
          }}>Create wallet</div>
          <div className="mobile-menu-item" onClick={() => {
            setOpen(false);
            window.open("https://academy.binance.com/en/glossary/bep-20", "_blank")
          }}>What is BEP-20</div>
          <div className="mobile-menu-item" onClick={() => {
            setOpen(false);
            window.open("https://laplandminerals.com/terms.pdf")
          }}>Terms & Conditions</div>
        </div>
      </SwipeableDrawer>

      <Modal
        open={openModalWallet}
        onClose={handleCloseModalWallet}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodyWallet}
      </Modal>
    </>
  )
}