import React from "react";
import "./GoldHistory.css"

export const GoldHistory = () => {
  return (
    <div className="gold-history-container">
      <div className="content">
        <h1>Gold: The Most Precious of Metals</h1>
        <p>When Hernán Cortes, the famous Spanish Conquistador, first came to the New World in the 16th century, he and
          his men encountered indigenous civilizations established there for millennia. They had never interacted with
          each other. A vast ocean separated them, and they did not have a common language, culture, or customs, yet the
          one thing that they did have in common was their insatiable appetite for gold. </p>
        <p>From the very beginning, something about gold attracted civilizations worldwide without those civilizations
          ever having contact with each other. </p>
        <p>Not only does gold’s natural beauty make it so mysteriously precious, but it may also be the most valuable
          metal in the world. </p>
        <p>It is a good conductor of electricity, does not tarnish or oxidize, and is highly malleable, allowing it to
          be worked with, pounded, and shaped without breaking. It is so malleable that it can be rolled thin enough to
          let light pass through it. Gold is also one of the few commodities that can be thought of as a currency or
          monetary asset.</p>
        <p>Many currencies around the world used to be backed by gold until recently. Although the gold standard has
          long since been abandoned, gold is still very effectively used as a haven asset in times of economic turmoil
          to preserve wealth.
        </p>
        <p>Gold benefits from diverse demands – as a safe-haven asset, as an investment, as an official sector strategic
          reserve, as jewelry, and as an industrial component. Gold is also highly liquid and historically preserves its
          value over time.</p>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <h1>Why Gold?</h1>
        <ul>
          <li>The top three demand drivers for gold are falling or negative interest rates, weaker USD, and loose U.S.
            fiscal and monetary policy.
          </li>
          <li>Central bank reserves account for 15% of all gold, while global ETFs consume 10%.</li>
          <li>Gold has daily volumes at $145 billion per day, nearly equaling daily S&P volumes.</li>
          <li>Investors often consider gold one of the safest investments of all time, and its price is usually
            inversely correlated to the US Stock Market.
          </li>
          <li>The U.S. Federal Reserve holds 8,133 tons of gold in its reserves.</li>
        </ul>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>

        <h1>Gold: A History of Obsession</h1>
        <p>People most likely first discovered gold in streams and rivers worldwide, with its beauty and luster catching
          the eye. The known history of gold goes back a long way, so far back that, according to the National Mining
          Association, it was first used by cultures in modern-day Eastern Europe in 4000 BC to make decorative
          objects.</p>
        <p>Gold was generally used solely for a couple of thousand years to create jewelry and idols for worship. This
          was until around 1500 BC when the ancient empire of Egypt, which benefited greatly from its gold-bearing
          region, Nubia, made gold the first official medium of exchange for international trade.</p>
        <p>Egypt created the Shekel, a coin that weighed 11.3 grams and became the standard unit of measure in the
          Middle East. The coin was made from a naturally occurring alloy called electrum which was about two-thirds
          gold and one-third silver. Around this time, the Babylonians discovered a fire assay method, one of the most
          effective ways to test gold purity, which is still used to this day. </p>
        <p>With the new coin-based system of currency came the establishment of the familiar “pounds,” “shillings,” and
          “pence,” with pounds being a pound of sterling silver. </p>
        <p>By 1284, Great Britain issued its first gold coin, the Florin. Across Europe in modern-day Italy, the
          Republic of Florence issued the first gold Ducat, which soon became the most popular gold currency globally
          and remained so for another five centuries.</p>
        <p>In 1787, the first U.S. gold coin was minted by a goldsmith named Ephraim Brasher. A few years later, in
          1792, the infant U.S. government passed the Coinage Act, placing the country on a bimetallic silver-gold
          standard, which stood in one form or another until 1976, when the U.S. finally abandoned the gold standard to
          be entirely based on fiat money. </p>
      </div>
    </div>
  )
}