import React from "react";
import {AboutBlock1} from "./AboutBlock1/AboutBlock1";
import {AboutBlock2} from "./AboutBlock2/AboutBlock2";

export const About = () => {
  return (
    <div>
      <AboutBlock1/>
      <AboutBlock2/>
    </div>
  );
}