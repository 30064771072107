import React from "react";
import "./Footer.css";

export const Footer = () => {
  return(
    <div className="footer">
      <div className="content">
        <div className="footer-inner">
          <div>
            <div className="caption">About Lapland Minerals Inc.</div>
            <div className="description">
              Lapland Minerals Inc.<br/>
              Panama City, Panama<br/>
              info (at) laplandminerals.com<br/>
            </div>
          </div>
          <div>
            <div className="caption">Useful Links</div>
            <div className="footer-menu">
              <div className="item"
                   onClick={() => window.open("https://laplandminerals.com/doc03202020220805111601.pdf")}>
                Audit report of assets
                <div className="arrow-right"/>
              </div>
              <div className="item" onClick={() => window.open("https://bscscan.com/token/0x5cFf35b3be2aEC8447C691b700e3f7c81A4ae074", "_blank")}>
                Token on BscScan
                <div className="arrow-right"/>
              </div>
              <div className="item" onClick={() => window.open("https://academy.binance.com/en/glossary/bep-20", "_blank")}>
                What is BEP-20 token?
                <div className="arrow-right"/>
              </div>
              <div className="item" onClick={() => window.open("https://laplandminerals.com/terms.pdf")}>
                Terms & Conditions
                <div className="arrow-right"/>
              </div>
            </div>
          </div>
          <div>
            <div className="caption">Follow us</div>
            <div className="description">
              Follow us and stay tuned for updates.
            </div>
            <div className="social">
              <div className="fb-icon" onClick={() => window.open("https://www.facebook.com/laplandminerals", "_blank")}/>
              <div className="twitter-icon" onClick={() => window.open("https://twitter.com/laplandminerals", "_blank")}/>
            </div>
          </div>
        </div>

        <div className="footer-hr"/>
        <div className="copyright">© 2022-{new Date().getFullYear()} Lapland Minerals Inc.</div>
      </div>
    </div>
  )
}