import React from "react";
import "./HomeBlock4.css";

export const HomeBlock4 = () => {
  return (
    <div className="home-block-4">
      <div className="content">
        <div className="home-block-4-center">
          <div>
            <h3>Documentation</h3>
            <h2>Download whitepaper</h2>
            <button className="download-button" onClick={() => window.open("https://laplandminerals.com/whitepaper.pdf")}>Download</button>
          </div>
        </div>
      </div>
    </div>
  )
}