import React from "react";
import "./HomeBlock2.css";
import {isMobile} from "../../../utils/checkDevice";

export const HomeBlock2 = () => {
  return (
    <div className="home-block-2">
      <div className="content">
        <div className="home-block-2-center">
          <div className="home-block-2-video-description">
            Play the story about Lapland Minerals Inc and the future of money.
          </div>
          <div className="home-block-2-iframe">
            <iframe
              width={isMobile() ? "100%" : "600"}
              height="337"
              src={`https://www.youtube.com/embed/mIAT21xsFCE`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </div>
        </div>
      </div>
    </div>
  )
}