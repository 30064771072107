import React from "react";
import "./AboutBlock2.css";
import "../About.css";

export const AboutBlock2 = () => {
  return (
    <div className="about-block-2">
      <div className="content">
        <div className="about-block-2-center">
          <div className="about-caption">Development of token</div>
          <div className="about-description">
            <div>Our main reason for creating a token based on natural minerals or metals is how the financial market
              has evolved over the last couple of years. We see how central banks worldwide print enormous amounts of
              their fiat currencies. The increased acceleration of their printing presses is already decreasing their
              money's purchasing power. Inflation reduces the value of their money, and in the end, it will hurt the
              population of each country. The control of the entire monetary system is in the hands of a few individuals
              who should never have that kind of power.
            </div>
            <div>Then we realized something in the crypto space. It's the same thing there as in the fiat currency
              system. A few people hold the markets in their hands, meaning they control them. Related to Bitcoin, we
              see so-called "whales" that have so much of the cryptocurrency that they can manipulate the instrument's
              price as they wish, and the losers are always the little people. So small fish gets wiped out, and large
              fish take the profits.
            </div>
            <div>Someone had to do something about this, and we decided it had to be us. We created LMGT. The token is
              backed by gold claims, extensively studied by governmental authorities, containing more than 120 tons.
              Each token holds 0,1 grams of gold, so the finite number of this first token is 1,2 billion. This number
              will never change.
            </div>
            <div>To ensure LMGT will not face the situation where most of it ends up in a few people's hands, we have
              decided to release it to the market using the so-called "airdrop" method. Instead, an airdrop is used to
              maximize the spread of tokens. It will reach as many users as possible in the least amount of time. Our
              agreement with our third party ensures that this will be executed most effectively and in line with both
              parties' vision and mission. This delivery method ensures no "whale" can manipulate or control the value
              of LMGT. The value of LMGT is and will always be 0.1-grams of gold!
            </div>
            <div>If you ask an economist what money is, they will answer: Money serves as a medium of exchange, a unit
              of accounting, and a store of value. That's what they learn in school, and sadly it's wrong. Money as we
              know it is no store of value; look at the purchasing power one US dollar has today and what that same
              dollar could buy back in 1980.
            </div>
            <div>We believe that we have found a solution to that. LMGT will always hold the same value as physical
              gold, it will be easy to use as a medium of exchange, and it will also be possible to use as an accounting
              unit.
            </div>
            <div>Lapland Minerals is not the first company to think of doing this; there are others with similar ideas,
              but we are the first to do it! Being the first will give us an advantage when others come to the table.
              And we have plans not only to be the first but also the best provider of minerals and rare earth
              mineral-backed cryptocurrencies and tokens. Our founders are actively negotiating new collateral
              agreements with providers in several countries to ensure that we always have claims on natural resources
              that will be used as collateral for new crypto tokens.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}