import React, {useState} from "react";
import "./HomeBlock1.css";
import axios from "axios";
import {notification} from "../../../components/Notification";

export const HomeBlock1 = () => {
  const [email, setEmail] = useState("");

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  }

  const handleRequestInfo = async (e) => {
    e.preventDefault();
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      await axios.post("https://binarytorrent.com/api/v1/users/lapland/request", {
        email: email,
      }).then(() => notification.success("More info requested"))
        .catch((error) => notification.warning(error));
    } else {
      notification.warning("Email is not valid");
    }

    setEmail("");
  }

  return (
    <div className="home-block-1">
      <div className="content">
        <div className="home-block-1-center">
          <div className="buy-container">
            <h1>Next generation store of value</h1>
            <p>
              Imagine a crypto token that can store value over time and is backed up by something precious in the
              physical world. Imagine that this token also can be used as a medium of exchange. Exchange and value are
              precisely what the founders of Lapland Mineral Gold Token imagined.
            </p>
          </div>
          <div className="request-form-caption">Request more info</div>
          <form className="request-form">
            <div className="form-item request-form-input">
              <input
                id="email"
                type="text"
                autoComplete="off"
                placeholder="Your Email"
                className=""
                value={email}
                onChange={handleChangeEmail}
              />
              {/*<label htmlFor="email">Your Email</label>*/}
            </div>
            <button onClick={handleRequestInfo}>Yes send more info</button>
          </form>
        </div>
      </div>

    </div>
  )
}