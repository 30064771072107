import React from "react";
import "./OurHistory.css";
import {isMobile} from "../../../utils/checkDevice";

export const OurHistory = () => {

  const data = [
    {
      id: 1,
      date: "sep - 21",
      text: "The idea was born to tokenize minerals using collateral agreements."
    },
    {
      id: 2,
      date: "jan - 22",
      text: "Making the collateral agreement backing up the first set of tokens to be created on the Binance Smart Chain."
    },
    {
      id: 3,
      date: "mar - 22",
      text: "The smart contract of the Lapland Minerals gold token deployed on Binance Smart Chain."
    },
    {
      id: 4,
      date: "apr - 22",
      text: "The first airdrop marketing agreement was signed with a third party operator to reach a worldwide audience for LMGT."
    },
    {
      id: 6,
      date: "dec - 23",
      text: "Listing of LMGT to public exchanges."
    },
  ];


  const renderTable = () => {
    if (!isMobile()) {
      return data.map((e, i) => {
        return <div className="our-history-table-row" key={i}>
          <div className="our-history-table-date">{e.date}</div>
          <div className={e.id === 6 ? "our-history-circle our-history-circle-none" : "our-history-circle"}>
            <div className={"our-history-circle-icon-" + e.id}/>
          </div>
          <div className="our-history-table-text">{e.text}</div>
        </div>
      });
    } else {
      return data.map((e, i) => {
        return <div className="our-history-table-row" key={i}>
          <div className="our-history-table-row-mobile">
            <div className="our-history-table-date-mobile">{e.date}</div>
            <div className="our-history-circle our-history-circle-none">
              <div className={"our-history-circle-icon-" + e.id}/>
            </div>
          </div>
          <div className="our-history-table-text">{e.text}</div>
          {e.id !== 6 && <div className="our-history-border-mobile"/>}
        </div>
      });
    }
  }


  return (
    <div className="our-history">
      <div className="content">
        <div className="our-history-center">
          <div className="our-history-container">
            <div className="our-history-caption">Our History</div>
            <div className="our-history-description">Over the years, the world has changed, and how we use money has
              also
              changed. Safeguarding and creating usability is at the root of Lapland Minerals Inc's founding.
            </div>

            <div className="our-history-table">
              {renderTable()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}